var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{staticClass:"notify-users-action-view",attrs:{"tag":"div"}},[_c('a-spin',{attrs:{"spinning":_vm.model.usersInfoService.isLoading}},[_c('chips-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.userSetter,
        'args': {
          'model': _vm.model,
          'key': 'users',
          'prefix': 'post_action_',
          'validation': 'required',
          'formatter': _vm.format
        }
      }}})],1),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.model,
        'key': 'message',
        'prefix': 'post_',
        'validation': 'required|editor_not_empty',
        'availableButtonsTypes': _vm.newPmButtons,
        'targetToUpload': {
          'target': 'target',
          'target_id': _vm.boardKey
        },
        'has-media': true,
        'buttonsHelpView': _vm.$t('select_editor_button_help_message'),
        'base-api-url': 'api.presscode.app/cabinet',
        'availableTabs': [ _vm.MessageEditorWithMediaTab.EditorTab, _vm.MessageEditorWithMediaTab.TextareaTab ],
        'disabled': _vm.disabled,
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }